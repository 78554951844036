import React from 'react';
import maleMusculature from 'assets/img/male-musculature.png';
import femaleMusculature from 'assets/img/female-musculature.png';
import { femalePaths, malePaths, classesFor } from './BodySvgs';
import * as _ from 'lib/utilities';

const randomPercentiles = (min,max) => {
    let metrics = {};
    Object.keys(malePaths).forEach(bpart => {
        metrics[bpart] = { percentile: _.random(min,max)}
    })

    return metrics;
}

class BodyGraphic extends React.Component {
    constructor(props) {
        super(props);
        this.scanRectRef = React.createRef();
        this.clipPathAboveRef = React.createRef();
        this.clipPathBelowRef = React.createRef();
        this.animationFrameId = null;
        this.svgHeight = 800;
        this.rectHeight = 50;
        this.scanSpeed = 8; // Speed of the scan
        this.curScanRound = 0;
        this.state = {
            position: 0,
            direction: 1,
            topMetrics: randomPercentiles(100,100),
            bottomMetrics: randomPercentiles(100,100)
        };
        this.colorInterval = setInterval(this.handleColorIntv,1000);
    }

    componentDidMount() {
        this.startAnimation();
        this.handleColorIntv();
    }

    componentWillUnmount() {
        // Cleanup the animation frame on unmount
        cancelAnimationFrame(this.animationFrameId);
        clearInterval(this.colorInterval);
    }

    render() {

        const { topMetrics, bottomMetrics } = this.state;
        const { gender, ...rest } = this.props;
        const paths = gender === 'male' ? malePaths : femalePaths;
        const img = gender === 'male' ? maleMusculature : femaleMusculature;
        const { imgWidth, imgHt, viewBox } = this.getImgDims();

        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="overlay-svg" width="310" height={this.svgHeight} viewBox={viewBox} id="male-body-svg" role="img" aria-label="Male musculature system" {...rest}>
                <title>Male musculature system</title>
                <desc>Image of male muscular system with color-coded overlays for arm size, waist size, chest size, shoulder size, forearm size, calf size, hip size, and thigh size.</desc>
                <defs>
                    <clipPath id={`bodyClip${gender}`}>
                        {Object.entries(paths).map(([bodyPart,paths]) => {
                            return (
                                <React.Fragment key={bodyPart}>
                                    {paths.map((path,i) => (<path d={path} key={i}></path>))}
                                </React.Fragment>
                            )
                        })}
                    </clipPath>
                    <linearGradient id="scanGradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="orange" stopOpacity="0" />
                        <stop offset="50%" stopColor="yellow" stopOpacity="0.8" />
                        <stop offset="100%" stopColor="orange" stopOpacity="0" />
                    </linearGradient>
                    <clipPath id="clipPathAbove">
                        <rect ref={this.clipPathAboveRef} x="0" y="0" width={imgWidth} height="0" />
                    </clipPath>
                    <clipPath id="clipPathBelow">
                        <rect ref={this.clipPathBelowRef} x="0" y="0" width={imgWidth} height={imgHt} />
                    </clipPath>
                </defs>
                <image width={imgWidth} height={imgHt} className="body-underlay" xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={img} ></image>
                {Object.entries(paths).map(([bodyPart,paths]) => {
                    return (
                        <React.Fragment key={bodyPart}>
                            {paths.map((path,i) => (<path clipPath="url(#clipPathAbove)" key={i} d={path} className={classesFor(bodyPart,topMetrics,false)}></path>))}
                        </React.Fragment>
                    )
                })}
                {Object.entries(paths).map(([bodyPart,paths]) => {
                    return (
                        <React.Fragment key={bodyPart}>
                            {paths.map((path,i) => (<path clipPath="url(#clipPathBelow)" key={i} d={path} className={classesFor(bodyPart,bottomMetrics,false)}></path>))}
                        </React.Fragment>
                    )
                })}

                <rect className="body-scan-line" clipPath={`url(#bodyClip${gender})`} ref={this.scanRectRef} x="0" y="0" width={imgWidth} height={this.rectHeight} />
          </svg>
        )
    }

    getImgDims = () => {
        const imgWidth = 310;
        const imgHt = 800;
        const viewBox = '0 0 310 800';

        return { imgWidth, imgHt, viewBox };
    }

    startAnimation = () => {
        const animateScan = () => {
            let { position, direction } = this.state;
            const { imgHt } = this.getImgDims();

            // Update position and reverse direction if necessary
            position += direction * this.scanSpeed;

            if (position <= 0 || position >= imgHt) {
                this.curScanRound += 1;
                this.handleColorIntv();
                direction *= -1;
            }

            // Update state and clip paths
            this.setState({ position, direction }, () => {
                const y = this.state.position;

                // Update the clip paths dynamically
                if (this.clipPathAboveRef.current) {
                    this.clipPathAboveRef.current.setAttribute('height', y);
                }
                if (this.clipPathBelowRef.current) {
                    this.clipPathBelowRef.current.setAttribute('y', y);
                    this.clipPathBelowRef.current.setAttribute('height', imgHt - y);
                }

                // Move the scan group
                if (this.scanRectRef.current) {
                    this.scanRectRef.current.setAttribute('y', y - this.rectHeight/2);
                }

                this.animationFrameId = requestAnimationFrame(animateScan);
            });
        };

        this.animationFrameId = requestAnimationFrame(animateScan);
    };

    handleColorIntv = () => {
        const ranges = [[75,100],[50,75],[25,50],[0,25]];
        let botRange,topRange;
        if(this.curScanRound === 0) {
            botRange = [101,101];
            topRange = ranges[0];
        } else {
            const curRound = this.curScanRound%4;
            console.log(curRound)

            if(curRound%2 === 1) {
                botRange = ranges[curRound];
                topRange = ranges[curRound-1];
            } else {
                topRange = ranges[curRound];
                const ind = curRound === 0 ? 3 : curRound-1;
                botRange = ranges[ind];
            }
        }
        this.setState({ topMetrics: randomPercentiles(...topRange), bottomMetrics: randomPercentiles(...botRange) });
    }
}

export const MaleGraphic = (props) => {

    return (
        <BodyGraphic gender="male" {...props} />
    )
}

export const FemaleGraphic = (props) => {

    return (
        <BodyGraphic gender="female" {...props} />
    )
}

